import React from "react";
import styles from "./SocialMedia.module.css";
import { logo_facebook, logo_instagram, logo_linkedin } from "utils/Assets";

const SocialMedia: React.FC = () => {
  return (
    <div className={styles.container}>
      <div className={styles.platform_container_l}>
        <a
          href="https://si.linkedin.com/in/sebastjan-rutart-s-p-grafi%C4%8Dno-oblikovanje-311105331"
          target="_blank"
          rel="noopener noreferrer"
          className={styles.linkedIn}
        >
          <img
            className={styles.logo_linkedIn}
            src={logo_linkedin}
            alt="Logo for LinkedIn"
          />
        </a>
      </div>

      <div className={styles.platform_container_f}>
        <a
          href="https://www.facebook.com/profile.php?id=61569304672032"
          target="_blank"
          rel="noopener noreferrer"
          className={styles.facebook}
        >
          <img
            className={styles.logo_facebook}
            src={logo_facebook}
            alt="Logo for Facebook"
          />
        </a>
      </div>
      <div className={styles.platform_container_i}>
        <a
          href="https://www.instagram.com/rutart_graficno_oblikovanje/"
          target="_blank"
          rel="noopener noreferrer"
          className={styles.instagram}
        >
          <img
            className={styles.logo_instagram}
            src={logo_instagram}
            alt="Logo for Instagram"
          />
        </a>
      </div>
    </div>
  );
};

export default SocialMedia;
